body {
	overflow-x: hidden;

	&.el-descriptor {
		background-color: $main-bkg;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
		text-transform: uppercase;
	}

	h1,
	h3 { font-family: $font-montserrat-black; }

	h2,
	h4,
	h5 { font-family: $font-montserrat-medium; }

	h1 {
		font-size: 50px;
		line-height: 54px;

		@include media-breakpoint-down(md) {
			font-size: 45px;
			line-height: 49px;
		}
	}

	h2 {
		font-size: 41px;
		line-height: 45px;

		@include media-breakpoint-down(md) {
			font-size: 36px;
			line-height: 40px;
		}
	}

	h3 {
		font-size: 36px;
		line-height: 40px;

		@include media-breakpoint-down(md) {
			font-size: 31px;
			line-height: 35px;
		}
	}

	h4 {
		font-size: 30px;
		line-height: 34px;

		@include media-breakpoint-down(md) {
			font-size: 25px;
			line-height: 29px;
		}
	}

	h5 {
		font-size: 25px;
		line-height: 29px;

		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 24px;
		}
	}

	h6 {
		font-family: $font-montserrat-extrabold;
		font-size: 20px;
		line-height: 24px;

		@include media-breakpoint-down(md) {
			font-size: 18px;
			line-height: 22px;
		}
	}

	p {
		color: $white;
		font-family: $font-montserrat-light;
		font-size: 18px;
		line-height: 22px;

		@include media-breakpoint-down(md) {
			font-size: 16px;
			line-height: 20px;
		}
	}

	.fnt-black {	font-family: $font-montserrat-black; }

	.extra-bold {	font-family: $font-montserrat-extrabold; }

	.semi-bold {	font-family: $font-montserrat-semibold; }

	.main-color { color: $main-color;	}

	.title-section {
		color: $main-color;
		margin-bottom: 3rem;
	}

	img {
		height: auto;
		max-width: 100%;
	}

	.card {
		background-color: $main-color;
		border-radius: 10px;
	}

	.btn-main {
		background-color: $primary;
		border-radius: 8px;
		color: $white;
		font-size: 20px;
		font-weight: 800;
		min-width: 172px;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			font-size: 18px;
			line-height: 22px;
		}

		@include media-breakpoint-down(md) {
			font-size: 16px;
			line-height: 20px;
		}

		&:hover {
			background-color: $secondary;
		}
	}
}

.navbar.el-descriptor {
	background: $main-color;
	// height: 102px;
	padding: 1rem;

	.navbar-toggler {
		border: unset;
		border-color: unset;
	}

	.nav-link {
		color: $white;
		font-size: 20px;
		font-weight: 500;
		padding-left: 10px;
		padding-right: 10px;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			font-size: 18px;
			line-height: 22px;
		}

		@include media-breakpoint-down(md) {
			font-size: 16px;
			line-height: 20px;
		}

		&:hover {
			color: $secondary;
		}
	}

	.btn-main {
		margin-left: 10px;
	}
}

.swiper-one-slide {

	.swiper-slide {
		.descripciones {
			background-image: url('img/elDescriptor/jpg/descripciones.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 703px;
		}

		.descripciones-2 {
			background-image: url('img/elDescriptor/jpg/descripciones-2.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 703px;
		}

		.descripciones-3 {
			background-image: url('img/elDescriptor/jpg/descripciones-3.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 703px;

			.pos-col {
				position: relative;
				top: 23em;
			}
		}

		.pos-col {
			position: relative;
			top: 7em;

			@include media-breakpoint-down(lg) {
				left: 0;
			}

			.gen-desc {
				font-family: $font-montserrat-extrabold;
				max-width: 466px;
			}

			.title-desc {
				color: $primary;
			}

			.txt-desc {
				margin-bottom: 2rem;
				max-width: 341px;
			}
		}
	}

	.swiper-pagination-one-slide {
		bottom: unset;
		display: inline-grid;
		left: 90%;
		top: 45%;
		width: 3%;

		.swiper-pagination-bullet {
			background: transparent;
			border: 3px solid $alt-color;
			height: 22px;
			margin: 0.5rem 0;
			width: 22px;

			&.swiper-pagination-bullet-active {
				background: $alt-color;
			}
		}
	}
}

.cards-with-icon {

	.container {
		position: relative;
		top: -4em;
		z-index: 1;

		@include media-breakpoint-down(sm) {
			top: 1em;
		}

		.card.icon-txt {
			min-height: 17.3em;

			@include media-breakpoint-down(md) {
				margin-bottom: 2rem;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 1rem;
			}

			.card-img-top {
				height: 83px;
				left: 1em;
				position: relative;
				top: 1em;
				width: 63px;
			}

			.card-body {
				padding: 1.25rem 0.75rem 0.5rem;

				.card-title {
					margin-top: 1.1rem;
				}
			}
		}
	}
}

.seo-col {
	margin-bottom: auto;
	margin-top: auto;

	@include media-breakpoint-down(lg) {
		left: 3em;
	}

	@include media-breakpoint-down(sm) {
		left: 0;
		padding-top: 2rem;
		text-align: center;
	}

	.title-seo {
		margin-bottom: 1.813rem;
	}

	.text-seo {
		margin-bottom: 0;
		text-transform: unset;
	}
}

.ahorra-col {
	left: 7em;
	margin-bottom: auto;
	margin-top: auto;
	position: relative;

	@include media-breakpoint-down(lg) {
		left: 3em;
	}

	@include media-breakpoint-down(sm) {
		left: 0;
		padding-top: 2rem;
		text-align: center;
	}

	.title-ahorra {
		margin-bottom: 1.125rem;
	}

	.text-ahorra {
		margin-bottom: 0;
		text-transform: unset;
	}
}

.hr-alt {
	border-top: 1px solid $alt-color;
}

.clients {
	padding-bottom: 1rem;
}

.cards-testimonials {
	padding-bottom: 6.875rem;
	padding-top: 3.75rem;

	.qualification {
		min-height: 18em;

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}

		.card-title {
			font-size: 20px;
			line-height: 24px;
		}

		.card-subtitle {
			color: $alt-color;
			font-family: $font-montserrat-light;
			font-size: 18px;
			line-height: 23px;
			margin-bottom: 1.563rem;
			text-transform: unset;
		}

		.card-img-bottom {
			bottom: 1em;
			height: 21px;
			left: 1em;
			position: relative;
			width: 122px;
		}
	}
}

.contact {
	background: url('img/elDescriptor/png/formulario.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 733px;

	.contact-col {
		margin-top: 1.9em;

		.title-contact {
			color: $main-color;
			margin-bottom: 0.875rem;
		}

		.txt-contact {
			color: $main-color;
			margin-bottom: 1.875rem;
			text-transform: unset;
		}

		.form-comming-soon {
			background: $white;
			border-radius: 8px;
			padding: 1rem;

			.form-group {
				padding: 18px 0 0;
				position: relative;
				width: 100%;
			}

			.form-field {
				background: transparent;
				border: 0;
				border-bottom: 2.36px solid $alt-color;
				color: $black;
				font-family: inherit;
				font-size: 1.1rem;
				outline: 0;
				padding: 7px 0;
				transition: border-color 0.2s;
				width: 100%;

				&::placeholder {
					color: transparent;
				}

				&:placeholder-shown ~ .form-label {
					cursor: text;
					font-size: 1.3rem;
					top: 20px;
				}

				&:required,
				&:invalid,
				&:focus {
					box-shadow: none;
				}
			}

			.label-txt {
				font-size: 20px;
				font-weight: 500;
			}

			.area-txt {
				background: $alt-color;
				border: 2.36px solid $alt-color;
			}

			.area-txt:focus {
				border-image: linear-gradient(to right, $primary, $secondary);
				border-image-slice: 1;
				border-width: 3px;
			}

			.form-label {
				display: block;
				font-size: 20px;
				font-weight: 500;
				position: absolute;
				text-transform: uppercase;
				top: 0;
				transition: 0.2s;
			}

			.form-field:focus {
				border-image: linear-gradient(to right, $primary, $secondary);
				border-image-slice: 1;
				border-width: 3px;
				padding-bottom: 6px;

				~ .form-label {
					color: $primary;
					display: block;
					font-size: 1rem;
					font-weight: 700;
					position: absolute;
					top: 0;
					transition: 0.2s;
				}
			}

			.btn-send {
				background-color: $primary;
				border-radius: 8px;
				color: $white;
				font-family: $font-montserrat-extrabold;
				font-size: 20px;
				min-width: 100px;
				text-transform: uppercase;

				&:hover {
					background-color: $secondary;
					color: $black;
				}
			}

			.form-note {
				border-radius: 2px;
				bottom: 0;
				color: $white;
				display: block;
				font-size: 16px;
				left: 0;
				line-height: 20px;
				position: fixed;
				text-align: center;
				width: 100%;
				z-index: 1202;
			}

			.form-confirm {
				background: $form-confirm;
				padding: 18px 0;
				width: 100%;
			}

			.form-alert {
				background: $form-error;
				padding: 18px 0;
				width: 100%;
			}

			.field-error {
				border: 1px solid $form-error;
			}
		}
	}
}

footer {
	padding-bottom: 2.813rem;
	padding-top: 2.5rem;

	.social-icons {
		padding-bottom: 2.5rem;

		.icon-social {
			margin-left: 1em;
			margin-right: 1em;
		}
	}

	.copyright {
		text-align: center;

		.txt-footer {
			color: $main-color;
			font-family: $font-montserrat-medium;
			font-size: 25px;
			line-height: 29px;

			@include media-breakpoint-down(md) {
				font-size: 22px;
				line-height: 26px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 19px;
				line-height: 24px;
			}
		}

		.hr-vertical {
			border-right: 1px solid $alt-color;
			border-top: 0;
			display: inline-flex;
			height: 3.25em;
			margin-block-end: 0;
			margin-block-start: 0;
			margin-inline-end: 0.5em;
			margin-inline-start: auto;
			position: relative;
			top: 1em;
			width: 0.5em;

			@include media-breakpoint-down(md) {
				border-right: 0;
				border-top: 1px solid $alt-color;
				display: block;
				height: 2em;
				margin-inline-end: auto;
				width: 65%;
			}

			@include media-breakpoint-down(xs) {
				width: 70%;
			}
		}
	}
}
