body {
	&.comming-soon {
		color: $black;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			text-transform: uppercase;
		}
	}

	.form-note {
		border-radius: 2px;
		bottom: 0;
		color: $white;
		display: block;
		font-size: 16px;
		left: 0;
		line-height: 20px;
		position: fixed;
		text-align: center;
		width: 100%;
		z-index: 1202;
	}

	.form-confirm {
		background: $form-confirm;
		padding: 18px 0;
		width: 100%;
	}

	.form-alert {
		background: $form-error;
		padding: 2.5rem 0;
		width: 100%;
	}

	.field-error {
		border: 1px solid $form-error !important;
	}
}
.navbar.comming-soon img {
	margin: 6rem 0;
	width: 218px;
}
.navbar.comming-soon {
	position: absolute;
	width: 100%;
}
#counter {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	p {
		color: $white;
		font-size: 14px;
		font-family: $font-montserrat-bold;
		text-align: center;
		text-transform: uppercase;
		width: 106px;
		span {
			font-size: 50px;
			font-family: Montserrat-light;
			border-bottom: 1px solid #fff;
			margin-bottom: 0rem;
			padding-bottom: 2rem;
			display: block;
		}
	}
}

.d-flex-fields {
	display: flex;
	justify-content: space-between;
	column-gap: 4rem;
}

main {
	background-image: url("img/indexBackground.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.section-contact {
	padding-top: 10rem;

	@include media-breakpoint-down(sm) {
		background-position: center 40%;
		background-size: auto;
		height: 100vh;
	}

	.title-contact {
		font-family: $font-montserrat-bold;
		font-size: 89px;
		font-weight: 900;
		line-height: 108px;
		margin-top: 4rem;
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

		@include media-breakpoint-down(lg) {
			font-size: 79px;
			line-height: 98px;
		}

		@include media-breakpoint-down(md) {
			font-size: 59px;
			line-height: 78px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 49px;
			line-height: 68px;
			margin-top: 3rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 35px;
			line-height: 54px;
		}
	}

	.subtitle-contact {
		font-family: $font-montserrat-light;
		font-size: 30px;
		line-height: 70px;
		margin: 1.8rem auto 4rem;
		color: $white;
		padding: 0.5rem;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			font-size: 57px;
			line-height: 60px;
			margin-bottom: 9.1rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 37px;
			line-height: 40px;
			margin-bottom: 11.6rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 27px;
			line-height: 30px;
			margin-bottom: 5.6rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 20px;
			line-height: 23px;
			margin-bottom: 7rem;
		}
	}

	.form-comming-soon {
		border-radius: 8px;
		padding: 14rem 1rem 4rem;

		@include media-breakpoint-down(sm) {
			height: 100%;
			width: 100%;
		}

		.form-group {
			padding: 18px 0 0;
			position: relative;
			width: 100%;
			margin-bottom: 3rem;
		}

		.form-field {
			background: transparent;
			border: 0;
			border-bottom: 1px solid #06c9b9;
			color: $white;
			font-family: inherit;
			font-size: 14px;
			outline: 0;
			padding: 7px 0;
			transition: border-color 0.2s;
			width: 100%;
			border-radius: 0;
			text-transform: uppercase;

			&::placeholder {
				color: transparent;
			}

			&:placeholder-shown ~ .form-label {
				cursor: text;
				font-size: 14px;
				top: 64px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 100;

				@include media-breakpoint-down(sm) {
					font-size: 1rem;
				}
			}

			&:required,
			&:invalid,
			&:focus {
				box-shadow: none;
			}
		}

		.label-txt {
			font-size: 1.3rem;
			font-weight: 500;

			@include media-breakpoint-down(sm) {
				font-size: 1rem;
			}
		}

		.area-txt {
			border: 2.36px solid $black;
			border-radius: 0;
		}

		.area-txt:focus {
			border-image: linear-gradient(to right, $primary, $secondary);
			border-image-slice: 1;
			border-width: 3px;
		}

		.form-control {
			background-color: transparent;
		}

		.form-label {
			display: block;
			font-size: 20px;
			font-weight: 500;
			position: absolute;
			top: 0;
			transition: 0.2s;
			color: #dddddd;
		}

		.form-field:focus {
			border-image: linear-gradient(to right, $primary, $secondary);
			border-image-slice: 1;
			border-width: 3px;
			padding-bottom: 6px;

			~ .form-label {
				color: $primary;
				display: block;
				font-size: 1rem;
				font-weight: 700;
				position: absolute;
				top: 0;
				transition: 0.2s;
			}
		}

		.btn-send {
			background-color: transparent;
			color: #fff;
			font-size: 14px;
			font-weight: 100;
			min-width: 204px;
			text-transform: uppercase;
			margin-top: 2rem;
			border: 2px solid;
			border-radius: 0;
			height: 45px;
			border-image: linear-gradient(to right, #06c9b9, #7634fb) 1;

			@include media-breakpoint-down(sm) {
				font-size: 0.75rem;
			}
		}
	}
}

.map {
	background-image: url("img/map.png");
	height: 350px;
	background-size: cover;
}

.img-text {
	margin: 4rem 0 0;
}

.footer {
	&.pre-footer {
		background-color: $white;
		p {
			font-size: 12px;
			line-height: initial;
		}
	}
	.container {
		padding: 1rem 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			color: $white;
			margin-bottom: 0;
			display: flex;
			column-gap: 1rem;
			align-items: center;
			font-size: 14px;
		}
		.socials {
			display: flex;
			column-gap: 0.5rem;
		}
		a {
			color: $white;
		}
	}
}
