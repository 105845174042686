@font-face {
	font-family: 'Montserrat-thin';
	src: url('assets/fonts/Montserrat-Thin.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-extraLight';
	src: url('assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-light';
	src: url('assets/fonts/Montserrat-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-regular';
	src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-medium';
	src: url('assets/fonts/Montserrat-Medium.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-semiBold';
	src: url('assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-bold';
	src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-extraBold';
	src: url('assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat-black';
	src: url('assets/fonts/Montserrat-Black.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}
